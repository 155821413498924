.LeftNav__wrapper {
  display: flex;
  flex: 0 0 auto;
}

.LeftNav {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  flex: 0 0 100%;
  min-width: 198px;
  width: 17.5vw;
  max-width: 256px;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  background-color: #1b1b1b;
  z-index: 101;
}

/* Profile section */
.Profile {
  position: sticky;
  top: 0;
  z-index: 3;
}

.Profile_opener {
  display: flex;
  margin: 0;
  padding: 12px calc(9.375% - 8px) 12px 9.375%;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
  color: currentColor;
  background-color: #000;
  border-bottom: 1px solid #000;
}

.Profile_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  margin-right: 12px;
}

.Profile_organizationName,
.Profile_name {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.Profile_name {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.Profile_organization {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.5;
  max-width: 100%;
  transition: opacity 50ms ease-in-out;
}

.Profile_organization:after {
  /* content: ""; */
  position: relative;
  right: 0;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top: 4px solid currentColor;
  margin-left: 6px;
  margin-top: 6px;
}

.Profile_avatar {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar_container.avatar_size {
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 14px;
}

.avatar_container {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 0;
  border-radius: 50%;
  overflow: hidden;
  font-weight: 500;
  background: #fff;
}

.user_avatar {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  color: rgb(5, 111, 138);
  background-color: #000;
}

.avatar_border {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
}

.avatar_initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: absolute;
  top: 0;
  left: 0;
}

.avatar > img {
  display: block;
}

.avatar_image {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: 50%;
}

.user_avatar:after {
  border-radius: 50%;
}

.avatar:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
/* Profile section end */

/* Main menu section */
.menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: inherit;
  overflow-y: auto;
}

.menuList {
  list-style: none;
  margin: 0;
  padding: 16px 0;
}

.menuItem {
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.menuItem > .menuList {
  padding: 4px 0 16px;
}

.menuItem > .menuList .menuItem {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.itemLink,
.itemLink:hover,
.itemLink:active,
.itemLink:focus {
  color: currentColor;
  text-decoration: none;
}

.itemLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.menuItem > .menuList .itemLinkContent {
  border-radius: 24px;
  padding: 4px calc(9.375% + 2px - 10px);
}

.itemLinkContent {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
}

.menuItem > .menuList .itemIcon {
  color: currentColor;
  opacity: 0.25;
}

.itemIcon {
  width: 16px;
  height: 16px;
  margin-right: 11px;
  flex-shrink: 0;
  position: relative;
}

svg:not(:root) {
  overflow: hidden;
}

.itemLink.isActive .itemLinkContent {
  background-color: #303030;
}

.itemLinkContent {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px calc(9.375% + 2px - 10px);
  border-radius: 32px;
}

.itemIcon {
  width: 16px;
  height: 16px;
  margin-right: 11px;
  flex-shrink: 0;
  position: relative;
}

/* .itemIcon.itemcount.mainmenu svg {
  clip-path: path("M0 16H16V9C11.0294 9 7 4.97056 7 0H0V16Z");
} */

.itemIcon.itemcount.mainmenu .countnumber {
  text-indent: 1px;
}

.countnumber {
  background-color: #ea552b;
  padding: 2px 4px 0 4px;
  min-width: 16px;
  height: 16px;
  display: block;
  text-align: center;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  border-radius: 8px;
  position: absolute;
  top: -8px;
  right: -8px;
  box-sizing: border-box;
}

a:hover,
a:active {
  outline: 0;
}

.itemLink:hover:not(.isActive) .itemLinkContent {
  background-color: #303030;
}

.addButton_right {
  margin-left: auto;
}

.addButton {
  cursor: pointer;
  width: 14px;
  height: 14px;
  opacity: 0.25;
  transition: opacity 50ms ease-in-out;
}

.addButton:hover {
  opacity: 1;
}
/* Main menu section end */

/* Smartview section */
.Smartview {
  padding: 0 0 24px 0;
  flex-grow: 1;
  /* box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 10%); */
}

.Smartview_top {
  margin: 16px 0 12px;
  padding: 0 9.375%;
}

.Smartview_header {
  display: flex;
  column-gap: 16px;
  padding: 8px 0;
}

.Smartview_title {
  font-size: 12px;
  line-height: 1.33333333;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
}

.Smartview_searchIcon {
  cursor: pointer;
  display: block;
  width: 14px;
  height: 14px;
  margin-left: auto;
  opacity: 0.25;
  transition: opacity 50ms ease-in-out;
}

.Smartview_list {
  margin: -4px 0 0 0;
  list-style: none;
}

.Smartview_item {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 5px;
}

.Smartview_link,
.Smartview_link:hover,
.Smartview_link:focus,
.Smartview_link:active,
.Smartview_link.active {
  color: #fff !important;
  text-decoration: none;
}

.Smartview_link {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.Smartview_linkContent {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px calc(20.375% + 2px - 10px);
  border-radius: 24px;
  /* margin-left: 20px; */
}

.Smartview_dragger {
  display: none;
  cursor: move;
}

.Smartview_bullet,
.Smartview_dragger {
  display: flex;
  align-items: center;
  margin-right: 12px;
  opacity: 0.5;
}

.Smartview_bullet svg,
.Smartview_dragger svg {
  width: 16px;
  height: 16px;
}

.Smartview_dragger {
  display: none;
  cursor: move;
}

.Smartview_editButton {
  display: none;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  opacity: 0.25;
  box-sizing: border-box;
  flex-shrink: 0;
  transition: opacity 200ms;
}

.Smartview_editButton svg {
  width: 16px;
  height: 16px;
}

.Smartview_link:hover .Smartview_linkContent,
.Smartview_link.active .Smartview_linkContent {
  background-color: rgba(255, 255, 255, 0.05);
}
/* Smartview section end */

/* Bottom section end */
.MenuList_bottom {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.collapse_Icon,
.external_Icon {
  display: flex;
  opacity: 0.25;
  margin-left: auto;
}

.collapse_Icon svg,
.external_Icon svg {
  width: 14px;
  height: 14px;
}

.collapse_Text {
  opacity: 0.25;
}
/* Bottom section end */

@media (max-width: 768px) {
  .LeftNav_wrapper {
    min-width: 256px;
    width: 100vw;
    margin-top: 56px;
    transform: translateX(-100%);
    transition: transform 200ms;
    z-index: 99999;
  }
}
