.inbox-page h1 {
  display: inline;
  vertical-align: -6px;
}

.list_count {
  display: block;
  color: #464a55;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 4px;
}

.inbox-page .inbox-actions {
  margin: 15px 0 10px 0;
}

.pull-left {
  float: left;
}

.inbox-page .inbox-actions input.check-all {
  margin: 0 7px 0 9px;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}

.inbox-page.view-inbox .action-archive,
.inbox-page.view-inbox .action-archive-selected,
.inbox-page.view-inbox .snooze-btn-group {
  display: inline-block;
}

.inbox-page.view-inbox .action-archive,
.inbox-page.view-inbox .action-archive-selected,
.inbox-page.view-inbox .snooze-btn-group {
  display: inline-block;
}

.glyphicons {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Regular";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicons {
  vertical-align: baseline;
}

.Btn[disabled],
.Btn.disabled {
  background: white;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  text-shadow: none !important;
}

.glyphicons-ok:before {
  content: "\E207";
}

.Btn .glyphicons:before {
  text-align: center;
  display: block;
  color: inherit;
}

.BtnGroup {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.snooze-btn-group {
  color: #262e39;
  text-shadow: none;
  line-height: 20px;
}

.inbox-page.view-inbox .action-archive,
.inbox-page.view-inbox .action-archive-selected,
.inbox-page.view-inbox .snooze-btn-group {
  display: inline-block;
}

.glyphicons-alarm:before {
  content: "\E054";
}

.Btn .glyphicons:before {
  text-align: center;
  display: block;
  color: inherit;
}

.better-caret {
  float: right;
  display: block;
  width: 26px;
  text-align: center;
  margin-right: -12px;
  position: relative;
}

.better-caret:after {
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-top: -1px;
  width: auto;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.inbox-page .inbox-actions .selected-count-str {
  font-size: 12px;
  padding-left: 5px;
}

.pull-right {
  float: right;
}

.inbox-page .filter-type {
  margin-right: 5px;
  display: inline;
}

ul,
ol {
  padding: 0;
}

.nav {
  list-style: none;
}

.inbox-page .nav-pills {
  font-size: 0.9em;
  margin: 0;
  display: inline-block;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}

.nav-tabs > li,
.nav-pills > li {
  float: left;
}

li {
  line-height: 20px;
}

.nav-pills a {
  outline: 0;
}

.nav > li > a {
  display: block;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}

.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  color: #fff;
  background-color: #3366ff;
}

.inbox-page .nav-pills a {
  padding: 6px 8px;
  margin: 0 1px;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}

.nav-tabs:after,
.nav-pills:after {
  clear: both;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}

.sortByFilter {
  display: inline-block;
}

.inbox-page .inbox-actions .filter {
  vertical-align: top;
}

.UsersSelect_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Btn--withDropdownArrow::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid currentColor;
  vertical-align: middle;
}

.Btn--withDropdownArrow.Btn--small::after {
  margin-top: -1px;
}

.inbox-page .user-filter,
.inbox-page .sortByFilter {
  display: inline-block;
}

.button-spantext {
  color: #616161;
  line-height: 16px;
  font-weight: normal;
  font-size: 12px;
  font-weight: 600;
}

.Btn--withDropdownArrow::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid currentColor;
  vertical-align: middle;
}

.Btn--withDropdownArrow.Btn--small::after {
  margin-top: -1px;
}

.avatarContainer {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 0;
  border-radius: 50%;
  overflow: hidden;
  font-weight: 500;
  background: #fff;
}

.avatarContainer.UserAvatar_small {
  width: 16px;
  height: 16px;
  font-size: 7.25px;
  line-height: 9px;
}

.UserAvatar {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.UsersSelect_avatar {
  margin-right: 8px;
  border-radius: 50%;
  vertical-align: text-bottom;
  display: inline-block;
  color: rgb(5, 111, 138);
  background-color: rgba(20, 181, 208, 0.114);
}

.UserAvatar_border {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
}

.UserAvatar_initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: absolute;
  top: 0;
  left: 0;
}

.UserAvatar_image {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: 50%;
}

.avatar > img {
  display: block;
}

.avatar:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

.UserAvatar:after {
  border-radius: 50%;
}

.inbox-page .inbox-list .item {
  position: relative;
  top: 0;
  background: white;
  border: solid 1px #e5e7ef;
  margin-top: -1px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  transition: top 70ms ease-in-out;
  will-change: top;
  line-height: 33px;
}

.list-keyboard-navigable tr.active,
.list-keyboard-navigable .item.active {
  outline: none;
}

.inbox-page .inbox-list .item:first-child {
  margin-top: 0;
}

.inbox-page .inbox-list .cell {
  display: block;
  padding: 5px 5px 3px 5px;
}

.list-keyboard-navigable tr > :first-child,
.list-keyboard-navigable .item > :first-child {
  position: relative;
}

.inbox-page .inbox-list .cell.cell-id {
  padding-left: 8px;
  padding-right: 6px;
  padding-top: 6px;
}

.list-keyboard-navigable tr.active > :first-child:before,
.list-keyboard-navigable .item.active > :first-child:before {
  content: "";
  width: 1px;
  background: #7da3c7;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.inbox-page .inbox-list .cell.cell-id input[type="checkbox"] {
  margin: -4px 0 0 0;
}

.inbox-page .inbox-list .inbox-icon {
  vertical-align: top;
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #eee;
  border: solid 1px #fff;
  box-shadow: 0 0 1px #ccc;
  position: relative;
  margin: 0;
  top: -1px;
  text-align: center;
  line-height: 38px;
}

.inbox-page .inbox-list .item-type-incoming_email .inbox-icon {
  background-color: #8cadc5;
}

.glyphicons {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Regular";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicons {
  vertical-align: baseline;
}

.inbox-page .inbox-list .inbox-icon .glyphicons {
  font-size: 16px;
  color: white;
  top: 2px;
  left: 8px;
  width: inherit;
}

.glyphicons-envelope:before {
  content: "\E011";
}

.inbox-page .inbox-list .cell.cell-lead_name {
  flex: 1;
  min-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inbox-page .inbox-list .cell.contact-buttons {
  line-height: 29px;
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  width: 60px;
  visibility: hidden;
}

.BtnGroup {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.BtnGroup > .Btn + .Btn {
  margin-left: -1px;
}

.BtnGroup > .Btn.Btn--icon:not(:only-child):not(.dropdown-toggle):last-child {
  width: auto;
  padding-left: 6px;
  padding-right: 9px;
}

.inbox-page .inbox-list .cell.grey {
  color: #aaa;
}

.inbox-page .inbox-list .cell.grey * {
  color: #262e39;
}

.inbox-page .inbox-list .cell .secondary {
  flex: 0 1 auto;
  color: #aaa;
  padding-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inbox-page .inbox-list .cell:last-child {
  padding-right: 10px;
}

.inbox-page .inbox-list .cell.cell-date {
  width: 80px;
  text-align: right;
}

.inbox-page .inbox-list .cell.cell-date {
  width: 80px;
  text-align: right;
}

div {
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.inbox-page .inbox-list .cell.cell-_type {
  display: flex;
  align-items: center;
  flex: 4;
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tik-icon {
  vertical-align: middle;
  margin-top: -3px;
}

.inbox-page .inbox-list .item-type-opportunity_due .inbox-icon {
  background-color: #a6e2d3;
}

.inbox-page .inbox-list .item-type-lead .inbox-icon {
  background-color: #be9797;
}

.inbox-page .big-msg {
  border: solid 1px #ddd;
  background: white;
  text-align: center;
  padding: 40px 0;
  min-height: 110px;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 10px 0;
  /* font-weight: normal; */
  color: #262e39;
}

/* h1,
h2,
h3 {
  line-height: 40px;
} */

h3 {
  font-size: 18px;
}

.inbox-page .big-msg h3 {
  margin: 0;
}

.inbox-page .big-msg p {
  color: #aaa;
  margin: 0;
}

.inbox-page .inbox-list .expanded-view {
  position: relative;
  z-index: 1;
  transition: opacity 200ms linear;
  line-height: 20px;
  will-change: opacity;
  cursor: auto;
  width: 100%;
}

.inbox-page .inbox-list .item.expanded .expanded-view.visible {
  opacity: 1;
}

.inbox-page .inbox-list .expanded-view > .header {
  margin: 0 0 5px 0;
  line-height: 32px;
  border-bottom: dotted 1px #ddd;
  padding: 17px 21px;
  cursor: pointer;
}

.inbox-page .inbox-list .inbox-icon {
  vertical-align: top;
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #eee;
  border: solid 1px #fff;
  box-shadow: 0 0 1px #ccc;
  position: relative;
  margin: 0;
  top: -1px;
}

.inbox-page .inbox-list .expanded-view .inbox-icon {
  margin: 0 10px 0 0;
}

.inbox-page .inbox-list .item-type-incoming_email .inbox-icon {
  background-color: #8cadc5;
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.inbox-page .inbox-list .expanded-view > .header h3,
.inbox-page .inbox-list .expanded-view > .header .close {
  line-height: 32px;
}

.inbox-page .inbox-list .expanded-view > .header .close {
  margin-left: 15px;
}

.pull-right {
  float: right;
}

.inbox-page.view-inbox .action-archive,
.inbox-page.view-inbox .action-archive-selected,
.inbox-page.view-inbox .snooze-btn-group {
  display: inline-block;
}

.inbox-page .inbox-list .expanded-view > .header .pull-right .Btn {
  margin-left: 5px;
}

.inbox-page .follow-up-view {
  margin-left: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 10px 0;
  /* font-weight: normal; */
  color: #262e39;
}

h3 {
  font-size: 18px;
}

.inbox-page .inbox-list .expanded-view > .header h3 {
  display: inline;
}

.inbox-page .inbox-list .expanded-view > .header h3,
.inbox-page .inbox-list .expanded-view > .header .close {
  line-height: 32px;
}

.inbox-page .inbox-list .expanded-view > .body {
  padding: 8px 23px 13px 23px;
}

.muted {
  color: #999;
}

.inbox-page .inbox-list .expanded-view > .body .lead-info {
  margin-bottom: 10px;
}

.inbox-page .inbox-list .expanded-view > .body .contact-buttons {
  margin: 5px 0;
}

.inbox-page .inbox-list .expanded-view > .body .contact-buttons .Btn {
  margin-right: 7px;
}

.inbox-page .inbox-list .expanded-view > .body .contact-buttons .Btn {
  margin-right: 7px;
}

.activity-inner.call .view-side,
.activity-inner.sms .view-side,
.activity-inner.email {
  word-wrap: break-word;
}

.activity-inner.email .emailthread-items > ul {
  margin: 0;
  list-style-type: none;
}

li {
  line-height: 20px;
}

.activity-inner.email .emailthread-item .preview {
  cursor: pointer;
  border-top: 1px solid #eee;
  padding: 3px 6px;
}

.activity-inner.email .emailthread-item.expanded .preview {
  background: #f8f8f8;
  border-bottom: 1px dashed #eee;
}

.activity-inner.email .emailthread-item .preview .contact,
.activity-inner.email .emailthread-item .preview .preview-right {
  color: #999;
}

.activity-inner.email .emailthread-item .preview .preview-right {
  float: right;
  margin-left: 12px;
}

.activity-inner.email .emailthread-item .preview {
  cursor: pointer;
  border-top: 1px solid #eee;
  padding: 3px 6px;
}

.activity-inner.email .emailthread-item .preview .contact,
.activity-inner.email .emailthread-item .preview .preview-right {
  color: #999;
}

.activity-inner.email .emailthread-item .preview time {
  border: none;
}

.activity-inner.email .emailthread-item .preview .preview-line {
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9em;
  text-overflow: ellipsis;
}

.activity-inner.email .emailthread-item.expanded .preview-line {
  white-space: normal;
}

.activity-inner.email .emailthread-item .preview .expando-arrow {
  color: #999;
  display: inline-block;
  width: 10px;
}

.activity-inner.email .emailthread-item.expanded .contact,
.activity-inner.email .emailthread-item.expanded .expando-arrow::before {
  color: #333 !important;
}

.activity-inner.email .emailthread-item.expanded .expando-arrow::before {
  content: "▾";
}

.activity-inner.email .emailthread-item.expanded .from-label {
  display: inline;
}

strong {
  font-weight: bold;
}

.activity-inner.email .emailthread-item .preview .contact {
  cursor: pointer;
}

.activity-inner.email .emailthread-item.expanded .contact,
.activity-inner.email .emailthread-item.expanded .expando-arrow::before {
  color: #333 !important;
}

.activity-inner.email .emailthread-item.expanded .contact .contact-email {
  display: inline;
}

.activity-inner.email .status-badge,
.activity-inner.sms .status-badge {
  margin-left: 5px;
}

.activity-inner.email .emailthread-item.expanded .preview-line {
  white-space: normal;
}

.activity-inner.email .emailthread-item.expanded .body-preview {
  display: none;
}

.activity-inner.email .emailthread-item .preview ul.headers {
  display: none;
  margin: 4px 0 0 13px;
  padding: 0 0 2px 0;
  font-size: 12px;
  list-style-type: none;
}

.activity-inner.email .emailthread-item.expanded ul.headers,
.activity-inner.email .emailthread-item.expanded .full-message {
  display: block;
}

.activity-inner.email .emailthread-item .preview ul.headers li {
  line-height: 16px;
}

strong {
  font-weight: bold;
}

.activity-inner.email .emailthread-item .full-message {
  display: none;
  padding: 0 16px 16px;
  margin: 0;
}

.activity-inner.email .emailthread-item.expanded ul.headers,
.activity-inner.email .emailthread-item.expanded .full-message {
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.activity-inner.email .emailthread-item .view-side {
  padding-top: 16px;
}

li {
  line-height: 20px;
}

.activity-inner.email .emailthread-item .view-side .email-link {
  margin: -38px -10px 4px 0;
}

.activity-inner.email .emailthread-item .view-side .email-actions {
  margin: -34px -10px 4px 0;
}

.activity-inner.email .emailthread-item .view-side .email-actions > a,
.activity-inner.email
  .emailthread-item
  .view-side
  .email-actions
  div.create-follow-up-container {
  float: left;
  margin-left: 6px;
  padding: 0 2px;
}

.activity-inner.email
  .emailthread-item
  .view-side
  .email-actions
  div.create-follow-up-container {
  padding: 0;
}

.activity-inner.email .emailthread-item .view-side .email-actions > a,
.activity-inner.email
  .emailthread-item
  .view-side
  .email-actions
  div.create-follow-up-container {
  float: left;
  margin-left: 6px;
  padding: 0 2px;
}

@media screen and (max-width: 767px) {
  .inbox-page .nav-pills {
    margin: 20px 0;
  }
  .inbox-page .filter-type {
    display: block;
  }
}

/* Topbar */
.Topbar-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 5%);
}

.Topbar {
  display: flex;
  background-color: #fff;
  height: 56px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 5%);
  color: #000;
  padding-left: 0;
  box-sizing: border-box;
  z-index: 102;
}

.searchBox_Container {
  flex: 1 0;
  margin: 8px 0;
  position: relative;
  z-index: 4;
}

.searchBox_wrap {
  position: relative;
  width: 500px;
  border: 1px solid transparent;
  background: #f1f1f1;
  border-radius: 3px;
}

.searchBox {
  position: relative;
  color: #262e39;
}

.search_Icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 12px;
  pointer-events: none;
  color: #464a55;
}

.IconSVG {
  line-height: 0;
  vertical-align: middle;
}

.IconSVG--regular svg {
  width: 16px;
}

.search_Textarea {
  min-height: 38px;
  max-height: 38px;
  resize: none;
  padding: 11px 0;
  padding-left: 36px;
  border: none;
  border-right: 36px solid;
  border-color: transparent !important;
  line-height: 1.14285714;
  letter-spacing: 0.25px;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  word-break: break-all;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  background: transparent;
  overflow: hidden;
  height: 38px;
}

.Topbar-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  margin: 8px;
}

.IconSVG--small svg {
  width: 14px;
}

.TopNav-button {
  align-items: center;
  box-sizing: border-box;
  color: #8792a2;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0 8px;
  width: auto;
}

.feedback-button {
  background: #fff;
  border: 1px solid #d1d2d7;
  border-radius: 3px;
  box-sizing: border-box;
  color: #3c4257;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
  padding: 7px 12px;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  white-space: normal;
}

.feedback-button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.feedback-button:active,
.feedback-button:focus,
.feedback-button:hover {
  outline: none;
  text-decoration: none;
}

.feedback-button:hover {
  background: #fff;
  color: #262e39;
}

.feedback-button:active,
.feedback-button:active:hover {
  border-color: rgba(0, 0, 0, 0.15);
  outline: none;
}

.feedback-button:active {
  background: #f8f8f8;
}

.feedback-button:focus:hover {
  outline: none;
}

.feedback-button-link {
  border-color: transparent;
  border-radius: 0;
  color: var(--primary-color);
  cursor: pointer;
}

.feedback-button-link:active,
.feedback-button-link:active:focus,
.feedback-button-link:active:hover,
.feedback-button-link:focus,
.feedback-button-link:hover {
  background-color: initial;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: var(--primary-color);
  text-decoration: underline;
  text-shadow: none;
}

.feedback-button-link,
.feedback-button-link:active {
  background-color: initial;
  background-image: none;
  box-shadow: none;
}

.feedback-button-link {
  transition: all 0.2s ease-out;
}

.feedback-button-link:active,
.feedback-button-link:focus,
.feedback-button-link:hover {
  text-decoration: none !important;
}

.feedback-button {
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  padding: 3px 8px 4px;
  position: relative;
  transition: all 0.24s, box-shadow 0.24s;
}

.feedback-button:not(.feedback-button-small):not(.feedback-button-mini) {
  height: 28px;
  min-height: 28px;
}

.feedback-button-link {
  color: #5469d4;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.feedback-button-link:hover {
  color: #1a1f36;
}

.IconSVG--regular svg {
  width: 16px;
}

.TopNav-buttons {
  display: flex;
  margin: 8px;
}

.TopNav-button button {
  color: currentColor;
}

.TopNavIcon,
.phone_Icon,
.profile_Icon,
.settings_Icon,
.switch_Icon,
.TopNav-button {
  align-items: center;
  box-sizing: border-box;
  color: #8792a2;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0 8px;
  width: auto;
}

.phone_Icon:active,
.phone_Icon:hover,
.settings_Icon:active,
.settings_Icon:hover,
.profile_Icon:active,
.profile_Icon:hover,
.switch_Icon:active,
.switch_Icon:hover,
.TopNav-button:active,
.TopNav-button:hover {
  border-radius: 3px;
  color: #262e39;
}

.TopNav-buttons svg {
  height: 20px;
  width: 25px;
}

.feedback-button:focus {
  background-position: 0;
}

.IconSVG {
  line-height: 0;
  vertical-align: middle;
}
