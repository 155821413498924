.atd-dropdown.enter {
  opacity: 0;
  transform: scale(0.9);
}
.atd-dropdown.enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.atd-dropdown.exit {
  opacity: 1;
}
.atd-dropdown.exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.Select--disabled {
  /* pointer-events: none; */
  opacity: 0.6;
}

.atd-select-menu--isGroup .Menu-item {
  padding-top: 0;
  padding-bottom: 0;
}

.Select {
  display: inline-block;
}

.Select #TypeSearchBox {
  padding-right: 35px;
}

.Select .iconn {
  position: absolute;
  display: inline-block;
  margin-top: 9px;
}
