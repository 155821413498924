.table tbody tr.reqst {
  background: #fff;
  border: 0;
  border-right: 0.5px solid #e5e7ef;
}

.table tbody tr.reqst:hover {
  background: #fafafa;
}

.table tbody tr.reqst td:first-child:not(.scrl) {
  border-left: 0.5px solid #e5e7ef;
}

.table tbody tr.reqst td {
  border-top: 0.5px solid #e5e7ef;
  border-bottom: 0.5px solid #e5e7ef;

  border-top: 0.5px solid #e5e7ef;
}

/* .table tbody tr.reqst .scrl {
  background: #fff;
  border-top: 0;
  border-right: 0;
} */

.table tbody tr.reqst .scrl {
  background: #fff;
  border-top: 0;
  border-right: 0;
  position: absolute;
  left: 4px;
  margin-top: -8px;
}

.table tbody tr.reqst .scrl + td {
  border-left: 0.5px solid #e5e7ef;
}

.reqst td.scrl {
  border-left: 0 !important;
}
