.ToggleBox {
  min-width: 0;
  margin: 0;
  padding: 0.01em 0 0;
  border: 0;
}

body:not(:-moz-handler-blocked) .ToggleBox {
  display: table-cell;
}

.ToggleBoxItem {
  position: relative;
  z-index: 1;
  width: 100%;
}

.ToggleBoxItem-pressable {
  position: relative;
  z-index: 1;
  height: 100%;
}

.ToggleBoxItem--checked,
.ToggleBoxItem--checked .ToggleBoxItem-pressable,
.ToggleBoxItem--checked:hover,
.ToggleBoxItem--checked:hover .ToggleBoxItem-pressable {
  z-index: 3;
}

.ToggleBoxItem:hover,
.ToggleBoxItem:hover .ToggleBoxItem-pressable {
  z-index: 2;
}

.ToggleBoxItem.Is--disabled {
  cursor: default;
  pointer-events: none;
}

.ToggleBox--connected.ToggleBox--direction--horizontal .ToggleBoxItem {
  margin-right: 1px;
}

.ToggleBox--connected.ToggleBox--direction--horizontal .ToggleBoxItem--isLast {
  margin-right: 0;
}

.ToggleBox--connected.ToggleBox--direction--vertical .ToggleBoxItem {
  margin-bottom: 1px;
}

.ToggleBox--connected.ToggleBox--direction--vertical .ToggleBoxItem--isLast {
  margin-bottom: 0;
}

.ToggleBoxItem-initial {
  width: 100%;
}

.ToggleBoxItem-label {
  position: relative;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ToggleBoxItem-source {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.ToggleBoxItem-area,
.ToggleBoxItem-copy {
  width: 100%;
  height: 100%;
}

.ToggleBoxItem-dot {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 8px;
  transform: scale(1.25);
  opacity: 0;
  transition: opacity var(--sail-duration), transform var(--sail-duration);
}

.ToggleBoxItem-dot--visible {
  transform: scale(1);
  opacity: 1;
}

.ToggleBoxItem-icon,
.ToggleBoxItem-title {
  transition: color var(--sail-duration);
}

.ToggleBox--connected .ToggleBoxItem:after,
.ToggleBox--connected .ToggleBoxItem:before {
  position: absolute;
  z-index: 1;
  display: block;
  background: #fff;
  content: "";
}

.ToggleBox--connected .ToggleBoxItem--isFirst:before,
.ToggleBox--connected .ToggleBoxItem--isLast:after {
  display: none;
}

.ToggleBox--direction--horizontal .ToggleBoxItem:after,
.ToggleBox--direction--horizontal .ToggleBoxItem:before {
  top: 0;
  bottom: 0;
  width: 1px;
}

.ToggleBox--direction--horizontal .ToggleBoxItem:before {
  left: -1px;
}

.ToggleBox--direction--horizontal .ToggleBoxItem:after {
  right: -1px;
}

.ToggleBox--direction--vertical .ToggleBoxItem:after,
.ToggleBox--direction--vertical .ToggleBoxItem:before {
  right: 0;
  left: 0;
  height: 1px;
}

.ToggleBox--direction--vertical .ToggleBoxItem:before {
  top: -1px;
}

.ToggleBox--direction--vertical .ToggleBoxItem:after {
  bottom: -1px;
}
