@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #262e39;
  background-color: #fafafa;
  height: 100%;
  /* overflow: hidden; */
}

#root,
#main,
#main > .container {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.container .content-area-wrap {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
}

div {
  display: block;
}

input,
button,
select,
textarea {
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
}

button,
input,
select,
textarea {
  vertical-align: middle;
}

img {
  /* max-width: 100%; */
  /* height: auto; */
  vertical-align: middle;
  border: 0;
}

ul,
ol {
  padding: 0;
}

.container .content-area-wrap {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
}

.container .content-area-wrap .content-area {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: #fafafa;
  min-width: 0;
  position: relative;
}

/* select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  border-radius: 3px;
  vertical-align: middle;
} */

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); */
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  color: #262e39;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

/* textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 8%);
} */

select {
  height: 32px;
}

.transform0 {
  transform: translateZ(0px);
}

.app-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  height: 100%;
  padding: 20px;
}

.PageWrapper {
  flex: 1 0 100%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.settings-page {
  padding: 0 !important;
}

.blue-button {
  background-color: #3366ff !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.blue-button:hover {
  background-color: #2952cc !important;
  color: #fff !important;
}

.bold-text {
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 14px;
  color: #262e39;
  font-weight: 600;
}

.common_title {
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  margin: 0 0 8px 0;
  color: #262e39;
}

.label_star {
  color: #d0021b;
}

.mg-top1 {
  margin-top: 10px !important;
}
.mg-top2 {
  margin-top: 20px !important;
}
.mg-top3 {
  margin-top: 30px !important;
}
.mg-top4 {
  margin-top: 40px !important;
}
.mg-top5 {
  margin-top: 50px !important;
}

.select-menu {
  font-weight: 700;
}

/* table */
.divContainer {
  display: table;
  width: 100%;
  border: 1px solid #e5e7ef;
  border-radius: 5px;
}

.list_item {
  display: table-row;
  height: 35px;
  line-height: 35px;
}

.list_item > div {
  background-color: #fff;
}

.header-bg > div {
  background: #fafbff;
  border-bottom: 1px solid #e5e7ef;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.list_item > div:first-child {
  border-radius: 10px 0 0 10px;
}

.list_item > div:last-child {
  border-radius: 0 10px 10px 0;
  padding-left: 10px;
}

.list_item_first,
.list_item_second,
.list_item_third,
.list_item_fourth {
  display: table-cell;
}

.list_item_first {
  width: 100px;
  padding-left: 15px;
}

.list_item_second {
  width: 100px;
}

.list_item_third {
  width: 30px;
}
/* table end */

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}

.flx {
  display: flex;
}

.max-500 {
  max-width: 500px;
}

.ver-middle {
  vertical-align: middle !important;
}
.ver-texttop {
  vertical-align: text-top !important;
}

.AppHeader {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.AppHeader-wrap {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.AppHeader_content {
  flex: 1;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AppHeader_title {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  font-weight: 700;
  color: #262e39;
}

.LeftNav ::-webkit-scrollbar {
  width: 3px;
}

.LeftNav ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
}

.LeftNav ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
}

.full-opacity {
  opacity: 1 !important;
}

.TopNav-buttons a {
  color: #8792a2;
}

.TopNav-buttons a:hover {
  color: #000;
}

.Screen {
  padding: 0 !important;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

.Button {
  position: relative;
  max-width: 100%;
}

.Btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  text-align: center;
  text-shadow: none;
  border-radius: 16px;
  background: white;
  font-size: 14px;
  font-weight: 400;
  color: #262e39;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1.14285714;
}

.Btn--transparent,
.Btn--transparent:focus,
.Btn--transparent[disabled],
.Btn--transparent[disabled]:hover {
  background: none;
  border: 1px solid transparent;
  box-shadow: none;
}

.Btn--small {
  line-height: 1.23076923;
  font-size: 13px;
  padding: 4px 12px;
}

.Btn_icon {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.Btn_icon svg {
  width: 16px;
  height: 16px;
}

.Btn--small .Btn_icon {
  width: 30px;
  height: 24px;
}

.Btn_text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Btn--small .Btn_icon + .Btn_text {
  margin-left: 18px;
}

.Btn--small .Btn_icon svg {
  width: 14px;
  height: 14px;
}

.Btn_icon + .Btn_text {
  margin-left: 24px;
}

.Btn--transparent:hover {
  background-color: #f1f1f1;
}

.Btn--large {
  line-height: 1.25;
  font-size: 16px;
  padding: 11px 16px;
  border-radius: 22px;
}

.Btn.Btn--danger {
  font-weight: 500;
}

.Btn--iconOnly .Btn_icon {
  left: -4px;
}

.Btn--link {
  border-color: transparent;
  cursor: pointer;
  color: var(--primary-color);
  border-radius: 0;
}

.Btn--link,
.Btn--link:active,
.Btn--link.active,
.Btn--link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border-color: transparent;
}

.Btn--link:hover,
.Btn--link:focus,
.Btn--link:focus:not(:hover),
.Btn--link:active,
.Btn--link:active:hover,
.Btn--link:active:focus,
.Btn--link.active,
.Btn--link.active:hover,
.Btn--link.active:focus {
  color: #3274a6;
  text-decoration: underline;
  background-color: transparent;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  border-color: transparent;
}

.Topbar {
  display: flex;
  background-color: #fff;
  height: 56px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 5%);
  color: #6b6e77;
  padding-left: 8px;
  box-sizing: border-box;
  z-index: 102;
}

.Btn[disabled],
.Btn.disabled {
  background: white;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  text-shadow: none !important;
}

.mobile_profile {
  display: none !important;
  margin: 12px;
}

.blue-text {
  color: #3366ff;
}

.width-100 {
  width: 100% !important;
}
.flx {
  display: flex !important;
}

.tik-icon {
  vertical-align: middle !important;
  margin-top: -3px !important;
}

.svg-icon {
  vertical-align: middle !important;
  margin-top: -3px !important;
}

.center {
  text-align: center !important;
}

.better-caret {
  float: right;
  display: block;
  width: 26px;
  text-align: center;
  margin-right: -12px;
  position: relative;
}
.better-caret:after {
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-top: -1px;
  width: auto;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media (max-width: 768px) {
  .container .content-area-wrap .content-area {
    margin-left: -100vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .Topbar {
    z-index: 1010;
    background-color: #000 !important;
    padding: 0;
  }
  .TopNav_mobileMenuOpener {
    color: #fff;
    display: block;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin: 8px 0;
    margin-left: -4px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .LeftNav_wrapper.visible {
    transform: none;
  }
  .LeftNav .Profile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .searchBox_wrap {
    width: 100% !important;
  }
  .TopNav-buttons {
    display: none !important;
  }
  .mobile_profile,
  .AppHeader_inner {
    display: block !important;
  }
  .Settings_content {
    min-width: 100% !important;
  }
  .list_item_third {
    width: 100px !important;
  }
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 30px;
  height: 14px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  margin-top: 3px;
  margin-right: 5px;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #283cb0;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 16px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
