.defaultColor {
  color: #262e39;
}
.mediumColor {
  color: #616161;
}
.lightColor {
  color: #777777;
}
.errorColor {
  color: #d0021b;
}
.linkColor {
  cursor: pointer;
  color: var(--primary-color);
}
.linkColor:hover {
  text-decoration: underline;
}
.invertedColor {
  color: #fff;
}
.displayText {
  margin: 0;
  font-weight: 700;
  color: #262e39;
}
.displayText.medium,
.displayText.large {
  font-size: 24px;
  line-height: 32px;
}
@media screen and (min-width: 768px) {
  .displayText.large {
    font-size: 30px;
    line-height: 40px;
  }
}
.heading {
  margin: 0;
}
.heading.small {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
/* // .heading.medium {
//   font-size: 16px;
//   line-height: 20px;
//   font-weight: 700;
// } */
.heading.large {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.caption {
  line-height: 16px;
}
.caption.small {
  font-size: 12px;
}
.caption.medium {
  font-size: 13px;
}
.uiText {
  line-height: 16px;
  font-weight: normal;
}
.uiText.smallest {
  font-size: 12px;
}
.uiText.small {
  font-size: 13px;
}
.uiText.base {
  font-size: 14px;
}
.uiText.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.25px;
  font-feature-settings: "pnum" on, "lnum" on, "cv09" on;
}
.uiText.uppercase.smallest {
  font-size: 10px;
}
.uiText.uppercase.small {
  font-size: 11px;
}
.uiText.uppercase.base {
  font-size: 12px;
}
.uiText.bold {
  font-weight: 600;
}
.uiText.italic {
  font-style: italic;
}
