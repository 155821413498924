/* Request Type Dropdown */
.req-type__dropdown {
  max-height: 40vh;
  overflow: auto;
}

/* My File Upload && Unspalash Upload */
.attachFiles__wrapper,
.royalityImages__wrapper {
  display: flex;
  align-items: center;
}
.attachFiles__wrapper input[type="file"],
.royalityImages__wrapper input[type="file"] {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.default-btn {
  display: inline-block;
  background-color: #fff;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.25s;
}
.default-btn:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.attachFiles__wrapper label svg,
.royalityImages__wrapper label svg {
  opacity: 0.7;
}
.attachFiles__status,
.royalityImages__status {
  color: #3c4257;
  font-size: 14px;
  margin-left: 16px;
  margin-top: 8px;
  width: 100px;
}
.files_wrapper {
  flex: 1;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.file {
  border: 1px solid #ccc;
  padding: 6px 12px;
  margin: 0 10px 10px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.file > div {
  display: flex;
}
.file-icon {
  width: 22px;
  height: 22px;
  background-color: #999;
  margin-right: 6px;
}
.file-name {
  color: #3c4257;
  font-size: 14px;
  padding-right: 20px;
}
.file-close {
  font-size: 20px;
  cursor: pointer;
}
/* Images Wrapper */
.imgs_wrapper {
  display: flex;
  gap: 4px;
}
.imgs_wrapper .imgs_holder {
  width: 50px;
  height: 80px;
  background: #ebebeb;
}
.imgs_wrapper .imgs_holder > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ------- Form ---------- */
.FormField__input--error,
.atd-Fieldset--error {
  color: #f44336;
  margin-top: 5px;
}

.atd-Fieldset--error {
  margin-left: 8px;
}

.FormField__input--hasError {
  border: 1px solid #f44336 !important;
}

.grid {
  display: grid;
  align-items: center;
  grid-column-gap: 12px;
}

.grid-align-top {
  align-items: flex-start;
}

.grid-column-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-column-3 {
  grid-template-columns: repeat(3, 1fr);
}

.p-0 {
  padding: 0 !important;
}

.field-col {
  align-items: flex-start;
}
