.DayPicker-container .DayPicker__horizontal,
.DayPicker-container .transition-container {
  width: 300px !important;
}

.DayPicker-container .DayPicker__horizontal {
  box-shadow: none;
  border-radius: 0;
  background: transparent;
}

.DayPicker-container .DayPicker__horizontal .DayPickerNavigation_button {
  position: absolute;
}

.DayPicker-container
  .DayPicker__horizontal
  .DayPickerNavigation_button:first-of-type {
  border: 0;
  top: 8px;
  left: 6px;
  padding: 0;
}

.DayPicker-container
  .DayPicker__horizontal
  .DayPickerNavigation_button:nth-of-type(2) {
  border: 0;
  top: 8px;
  right: 24px;
  padding: 0;
}

.DayPicker-container .DayPicker__horizontal .navArrow {
  height: 34px;
  width: 34px;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: 50%;
  background-color: initial;
}

.DayPicker-container .DayPicker__horizontal .navArrow.navPrev {
  background-image: var(--svg-dashboard-icon-right-arrow);
  transform: rotate(180deg);
  left: 0;
}

.DayPicker-container .DayPicker__horizontal .navArrow.navNext {
  background-image: var(--svg-dashboard-icon-right-arrow);
  right: 0;
}

.DayPicker-container .DayPicker__horizontal .DayPicker_weekHeader {
  color: #8792a2;
}

.DayPicker-container .DayPicker__horizontal .DayPicker_weekHeader li {
  height: 20px;
  width: 14.28571%;
}

.DayPicker-container .DayPicker__horizontal .DayPicker_weekHeader li small {
  font-size: 11px;
}

.DayPicker-container
  .DayPicker__horizontal
  .DayPicker_weekHeader:first-of-type {
  padding: 0 4px;
  top: 48px;
}

.DayPicker-container .DayPicker__horizontal .DayPicker_weekHeaders__horizontal {
  margin-left: 0;
}

.DayPicker-container .CalendarMonthGrid__horizontal {
  left: 0;
  margin-top: 10px;
}

.DayPicker-container .CalendarMonth {
  padding: 0 13px;
}

.DayPicker-container .CalendarMonth_caption {
  color: #3c4257;
  font-size: 15px;
  margin-top: 6px;
  margin-bottom: 40px;
  padding: 0;
}

.DayPicker-container .CalendarMonth_table {
  border-collapse: initial;
}

.DayPicker-container .CalendarMonth_table td {
  border: 0;
  color: #3c4257;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-right: -1px;
  margin-bottom: -1px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #e3e8ee;
  background: var(--light-color);
  box-sizing: border-box;
  position: relative;
  padding-top: 10px;
}

.DayPicker-container .CalendarMonth_table td:not(.CalendarDay) {
  background: none;
  border: none;
}

.DayPicker-container .CalendarDay:active {
  background: var(--light-color);
}

.DayPicker-container .CalendarDay.CalendarDay__default:active {
  background: #e3e8ee;
}

.DayPicker-container .CalendarDay.CalendarDay__blocked_calendar,
.DayPicker-container .CalendarDay.CalendarDay__blocked_calendar:active,
.DayPicker-container .CalendarDay.CalendarDay__blocked_out_of_range {
  background: var(--light-color);
  color: #a3acb9;
  cursor: default;
}

.DayPicker-container .CalendarDay.CalendarDay__selected_span {
  background: var(--primary-color200);
  color: #fff;
  border-color: rgba(143, 129, 254, 0.16);
  z-index: 1;
}

.DayPicker-container .CalendarDay.CalendarDay__selected,
.DayPicker-container .CalendarDay.CalendarDay__selected_end,
.DayPicker-container .CalendarDay.CalendarDay__selected_start {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
  z-index: 2;
}

.DayPicker-container .CalendarDay.CalendarDay__selected:active,
.DayPicker-container .CalendarDay.CalendarDay__selected_span:active {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  div[role="button"]
  .navArrow:focus {
  box-shadow: 0 0 0 4px rgba(143, 129, 254, 0.28),
    0 0 1px 1px rgba(83, 63, 219, 0.16);
  border-radius: 4px;
}

.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  .CalendarDay:focus {
  box-shadow: 0 0 0 4px rgba(209, 190, 254, 0.56),
    0 0 1px 1px rgba(83, 63, 219, 0.28);
  border-radius: 4px;
  z-index: 3;
}

.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  .CalendarDay.CalendarDay__selected:focus,
.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  .CalendarDay.CalendarDay__selected_end:focus,
.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  .CalendarDay.CalendarDay__selected_span:focus,
.DayPicker-container:not([data-input-method="mouse"]):not([data-input-method="touch"])
  .CalendarDay.CalendarDay__selected_start:focus {
  box-shadow: 0 0 0 4px rgba(209, 190, 254, 0.56),
    0 0 1px 1px hsla(0, 0%, 100%, 0.64);
}

.db-DatePickerWithPresets .CalendarMonth,
.db-DatePickerWithPresets .CalendarMonthGrid,
.db-DatePickerWithPresets .DayPicker {
  background: transparent !important;
}

.DayPickerNavigation_button__default {
  background-color: transparent;
}
