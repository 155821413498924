.Tooltip_tooltip {
  width: -webkit-max-content;
  width: max-content;
  word-wrap: break-word;
  opacity: 0.95;
}
.Tooltip_tooltip[data-placement^="bottom"] .Tooltip_arrow {
  top: -2px;
}
.Tooltip_tooltip[data-placement^="top"] .Tooltip_arrow {
  bottom: -2px;
}
.Tooltip_tooltip[data-placement^="left"] .Tooltip_arrow {
  right: -2px;
}
.Tooltip_tooltip[data-placement^="right"] .Tooltip_arrow {
  left: -2px;
}
.Tooltip_content {
  font-size: 11px;
  line-height: 1.5;
  padding: 7px;
  border-radius: 3px;
  color: #fff;
  background: #262e39;
  text-align: center;
  max-width: 100%;
}
.Tooltip_arrow,
.Tooltip_arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.Tooltip_arrow::before {
  content: "";
  transform: rotate(45deg);
  background: #262e39;
}
