.AppHeader_tabsWrap_349_team {
  z-index: 1;
  bottom: -1px;
  left: 0;
}
.tabs {
  display: flex;
  margin: 0;
  /* border-bottom: 1px solid #e3e8ee;
	 */
}
.tabs.border {
  border-bottom: 1px solid #e3e8ee;
}
.withMargin {
  margin: 30px 0 5px;
}
.tab:not(:last-child) {
  margin-right: 16px;
}
.tab {
  display: flex;
}
.tabButton {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0px 0 10px;
  margin-bottom: -1px;
  color: var(--theme-gray-color);
  transition: color 0.2s ease;
}
.tabButton.active::after {
  background-color: #262e39;
}
.tabButton:not(.active):hover::after {
  background-color: #b2b2b2;
}
.tabButton.active,
.tabButton:hover {
  outline: none;
  color: #262e39;
  text-decoration: none;
  cursor: pointer;
}
.tabButton.active .badge,
.tabButton:hover .badge,
.tabButton.active .icon,
.tabButton:hover .icon {
  color: #262e39;
}
.tabButton.active::after {
  background-color: #262e39;
}
.tabButton::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 1px;
  background-color: transparent;
  transition: background-color 0.2s ease;
}
.active:hover {
  pointer-events: none;
}
.count {
  margin-left: 8px;
  vertical-align: bottom;
  min-width: 1em;
  line-height: 1;
}
.fullWidth.center {
  /* justify-content: space-around;
	 */
  justify-content: left !important;
}
.fullWidth.center a {
  padding-left: 8px;
  padding-right: 8px;
}
.team .tabButton.active,
.team .tabButton:hover {
  color: #3c4257 !important;
  font-weight: 500 !important;
  margin-left: 2px !important;
}
.team .tabButton::after {
  height: 0 !important;
}
.action {
  margin-left: auto;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.icon {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  color: gray-40;
  transition: color 0.2s ease;
}
.badgeWrap {
  margin-left: 6px;
  vertical-align: bottom;
  min-width: 1em;
  line-height: 1;
}
.badge {
  border-color: #e2e2e2;
  color: #777;
  background-color: white;
}
