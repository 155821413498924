.unsplashImgs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.unsplashImgs img {
  display: block;
  flex: 1 1 100px;
  height: 100px;
  background-color: #ebebeb;
  object-fit: cover;
  object-position: center;
  border: 2px solid transparent;
  background-color: #fff;
  transition: 0.25s;
}
.unsplashImgs img:hover {
  cursor: pointer;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.unsplashImgs img.active {
  transform: translateY(0px);
  border: 2px solid rgb(84, 105, 212);
}
