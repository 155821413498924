.FullscreenView {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.FullscreenView--isLayer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index: var(--sail-zIndex-contextualLayer); */
}

.FullscreenContent--scroll {
  overflow: hidden;
  overflow-y: auto;
}

.FullscreenHeader {
  background-color: var(--light-color);
}
