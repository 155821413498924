.atd-dropdown-wrapper.dropdown {
  width: auto;
}
.atd-dropdown,
.atd-dropdown-exited {
  transform: scale(0.8);
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
    transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
}
.atd-dropdown-entered,
.atd-dropdown-entering {
  transform: scale(1);
  opacity: 1;
}
.atd-dropdown-exiting {
  transform: scale(0.8);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
}
/* .atd-dropdown.enter {
  opacity: 0;
  transform: scale(0.9);
}
.atd-dropdown.enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.atd-dropdown.exit {
  opacity: 1;
}
.atd-dropdown.exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
} */

/*== start of code for tooltips ==*/

.ContextualLayer-container {
  position: absolute;
  z-index: 1000;
}

.ContextualLayer--hidden {
  position: fixed;
  top: -200vh;
  left: 0;
}

.ContextualLayer--contained {
  z-index: 0;
}

.ContextualLayer-container:not(.ContextualLayer--pointerEvents) {
  pointer-events: none;
}

.ContextualLayer--inModal {
  z-index: 1000;
}

.ContextualPopover {
  display: -ms-flexbox;
  display: flex;
}

.ContextualLayer-context--anybottom.ContextualLayer-layer--anytop
  .ContextualPopover {
  -ms-flex-direction: column;
  flex-direction: column;
  transform-origin: 50% -11px;
}

.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  transform-origin: 50% calc(100% + 11px);
}

.ContextualLayer-context--anyright.ContextualLayer-layer--anyleft
  .ContextualPopover {
  -ms-flex-direction: row;
  flex-direction: row;
}

.ContextualLayer-context--anyleft.ContextualLayer-layer--anyright
  .ContextualPopover {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.ContextualPopover-arrowContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
}

.ContextualPopover-arrow {
  z-index: 1;
  width: 21px;
  height: 21px;
  margin: -7px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiM4ODk4QUEiIGZpbGwtb3BhY2l0eT0iLjEiIGQ9Ik0xIDkuMDkyaDE5bC02LjQwMi02Ljc0Yy0xLjcxNy0xLjgwNi00LjQ4NS0xLjgtNi4xOTYgMEwxIDkuMDkzek0yMC4zNDIgOGwtNi4wMi02LjMzNmMtMi4xMDgtMi4yMi01LjUzOC0yLjIxOC03LjY0NSAwTC42NTggOGgxOS42ODR6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNDAyIDIuMzUzYzEuNzExLTEuODAxIDQuNDgtMS44MDcgNi4xOTYgMEwyMCA5LjA5M0gxbDYuNDAyLTYuNzR6Ii8+PC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: 50%;
}

.ContextualLayer-context--anybottom.ContextualLayer-layer--anytop
  .ContextualPopover-arrowContainer {
  padding-top: 5px;
}

.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover-arrowContainer {
  padding-bottom: 5px;
}

.ContextualLayer-context--anyright.ContextualLayer-layer--anyleft
  .ContextualPopover-arrowContainer {
  padding-left: 5px;
}

.ContextualLayer-context--anyleft.ContextualLayer-layer--anyright
  .ContextualPopover-arrowContainer {
  padding-right: 5px;
}

.ContextualLayer-context--left.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--right.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topleft.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topright.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer {
  padding-top: 15px;
}

.ContextualLayer-context--bottomleft.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomright.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--left.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--right.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer {
  padding-bottom: 15px;
}

.ContextualLayer-context--bottom.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomleft.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--top.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topleft.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer {
  padding-left: 15px;
}

.ContextualLayer-context--bottom.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomright.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--top.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topright.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer {
  padding-right: 15px;
}

.ContextualLayer-context--bottom.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomleft.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--left.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--right.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--top.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topleft.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topleft.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topright.ContextualLayer-layer--topleft
  .ContextualPopover-arrowContainer {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.ContextualLayer-context--bottom.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomleft.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomright.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--bottomright.ContextualLayer-layer--topright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--left.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--right.ContextualLayer-layer--bottomleft
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--top.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer,
.ContextualLayer-context--topright.ContextualLayer-layer--bottomright
  .ContextualPopover-arrowContainer {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover-arrow {
  transform: rotate(180deg);
}

.ContextualLayer-context--anyleft.ContextualLayer-layer--anyright
  .ContextualPopover-arrow {
  transform: rotate(90deg);
}

.ContextualLayer-context--anyright.ContextualLayer-layer--anyleft
  .ContextualPopover-arrow {
  transform: rotate(-90deg);
}

.ContextualPopover-contents {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: #fff;
  border-radius: var(--sail-radius);
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1),
    0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.ContextualPopover-animate,
.ContextualPopover-animate-exited {
  transform: scale(0.85);
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
    transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
}

.ContextualPopover-animate-exiting {
  transform: scale(0.95);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
}

.ContextualPopover-animate-entered,
.ContextualPopover-animate-entering,
.ContextualPopover-noAnimation {
  transform: scale(1);
  opacity: 1;
}

.ContextualLayer-container {
  position: absolute;
  /* z-index: 300;
  margin-top: -72px;
  margin-left: -40px;
  text-align: center; */
}
.ContextualPopover {
  display: -ms-flexbox;
  display: flex;
}
.ContextualLayer-context--anybottom.ContextualLayer-layer--anytop
  .ContextualPopover {
  -ms-flex-direction: column;
  flex-direction: column;
  transform-origin: 50% -11px;
}
.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  transform-origin: 50% calc(100% + 11px);
}
.ContextualLayer-context--anyright.ContextualLayer-layer--anyleft
  .ContextualPopover {
  -ms-flex-direction: row;
  flex-direction: row;
}
.ContextualLayer-context--anyleft.ContextualLayer-layer--anyright
  .ContextualPopover {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.ContextualPopover-arrowContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
}
.ContextualPopover-arrow {
  z-index: 1;
  width: 21px;
  height: 21px;
  margin: -7px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiM4ODk4QUEiIGZpbGwtb3BhY2l0eT0iLjEiIGQ9Ik0xIDkuMDkyaDE5bC02LjQwMi02Ljc0Yy0xLjcxNy0xLjgwNi00LjQ4NS0xLjgtNi4xOTYgMEwxIDkuMDkzek0yMC4zNDIgOGwtNi4wMi02LjMzNmMtMi4xMDgtMi4yMi01LjUzOC0yLjIxOC03LjY0NSAwTC42NTggOGgxOS42ODR6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNDAyIDIuMzUzYzEuNzExLTEuODAxIDQuNDgtMS44MDcgNi4xOTYgMEwyMCA5LjA5M0gxbDYuNDAyLTYuNzR6Ii8+PC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: 50%;
}
.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover-arrowContainer {
  padding-bottom: 5px;
}
.ContextualLayer-context--anytop.ContextualLayer-layer--anybottom
  .ContextualPopover-arrow {
  transform: rotate(180deg);
}
.ContextualPopover-contents {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1),
    0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
@keyframes openPopover {
  from {
    transform: scale(0.85);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
    /* transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
      transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1); */
  }
}
@keyframes closePopover {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.85);
    opacity: 0;
  }
}
.ContextualPopover-animate,
.ContextualPopover-animate-exited {
  animation: closePopover 0.25s ease-in-out forwards;
}
.ContextualPopover-animate-entered,
.ContextualPopover-animate-entering,
.ContextualPopover-noAnimation {
  animation: openPopover 0.25s ease-in-out forwards;
}

.ContextualPopover-contents--scrollContainer {
  position: relative;
  z-index: 2;
  overflow-y: auto;
}
