.Spinner-svg {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  color: var(--sail-color-gray-400);
  transform-origin: 50% 50%;
  transition-timing-function: ease;
  transition-property: opacity, transform;
  animation: SpinnerAnimationShow 0.25s ease normal,
    SpinnerAnimationRotation 0.6s linear infinite;
}

.Spinner--size--large .Spinner-svg {
  width: 24px;
  height: 24px;
}

.Spinner--size--small .Spinner-svg {
  width: 12px;
  height: 12px;
}

.Spinner--color--white .Spinner-svg {
  color: #fff;
}

@keyframes SpinnerAnimationShow {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SpinnerAnimationRotation {
  to {
    transform: rotate(1turn);
  }
}
