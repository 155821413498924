#chk {
  position: absolute;
}
#chk .Checkbox {
  font-size: 18px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.2s ease;
}
#chk .Checkbox-input {
  margin: 0;
  appearance: none;
  display: block;
  background-color: rgb(255, 255, 255);
  height: 14px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  min-width: 14px;
  width: 14px;
  border-radius: 4px;
  outline: none;
  transition: all 0.2s ease;
}
#chk .Checkbox-input:checked {
  position: relative;
  background-color: rgb(84, 105, 212);
  border-color: transparent;
  /* The checkmark */
}
#chk .Checkbox-input:checked::after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 5px;
  height: 8px;
  top: 1px;
  left: 1px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  transform-origin: bottom right;
  transform: translateX(0) translateY(1px) rotate(45deg);
}
#chk .Checkbox-input:focus {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 12%) 0px 1px 1px 0px,
    rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}
#chk .Checkbox-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
