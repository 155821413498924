.header {
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flow-root;
}
.inner {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
:global(.settings-content) .inner {
  padding-top: 12px;
  padding-bottom: 12px;
}
.hasTabs .inner {
  padding-bottom: 40px;
}
.marginBottom {
  margin-bottom: 20px;
}
.spreadForSettings {
  margin-top: -20px;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
}
@media screen and (min-height: 480px) {
  .spreadForSettings.sticky {
    top: -20px;
  }
}
.spreadForApp {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.fullScreen {
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}
.fullScreen .inner {
  padding-top: 8px;
  padding-bottom: 8px;
}
@media screen and (min-width: 1120px) {
  .fullScreen {
    padding-left: 24px;
    padding-right: 24px;
  }
  .fullScreen .inner {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.title {
  flex: 1;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hasBreadcrumbs .title {
  margin-top: 12px;
}
.actions {
  display: flex;
  align-items: center;
}
.actions > *:not(:first-child) {
  margin-left: 8px;
}
.breadcrumbsWrap {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 0;
}
.tabsWrap {
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0;
}
.titleSub {
  display: block;
  color: #464a55;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 4px;
}
@media screen and (max-width: 1119px) {
  .breakActionsMedium .inner {
    display: block;
  }
  .breakActionsMedium .actions {
    margin-top: 16px;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .breakActionsSmall .inner {
    display: block;
  }
  .breakActionsSmall .actions {
    margin-top: 16px;
    justify-content: flex-start;
  }
}
@media screen and (min-height: 480px) {
  .sticky {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
