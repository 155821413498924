.UnstyledLink {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.UnstyledLink:hover {
  color: inherit;
}

.Flex-flex {
  display: -ms-flexbox;
  display: flex;
}

.Flex-inlineFlex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.Flex-alignContent--flexStart {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.Flex-alignContent--flexEnd {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.Flex-alignContent--center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.Flex-alignContent--spaceBetween {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.Flex-alignContent--spaceAround {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.Flex-alignContent--stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.Flex-alignItems--flexStart {
  -ms-flex-align: start;
  align-items: flex-start;
}

.Flex-alignItems--flexEnd {
  -ms-flex-align: end;
  align-items: flex-end;
}

.Flex-alignItems--center {
  -ms-flex-align: center;
  align-items: center;
}

.Flex-alignItems--baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.Flex-alignItems--stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.Flex-direction--row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.Flex-direction--rowReversed {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.Flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.Flex-direction--columnReverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.Flex-justifyContent--flexStart {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Flex-justifyContent--flexEnd {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.Flex-justifyContent--spaceBetween {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.Flex-justifyContent--spaceAround {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.Flex-wrap--nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.Flex-wrap--wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Flex-wrap--wrapReverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.Box-root {
  box-sizing: border-box;
}

.Box-hideIfEmpty:empty {
  display: none;
}

.Box-background--white {
  background-color: #fff;
}

.Box-background--slate100 {
  background-color: #f6f8fa;
}

.Box-background--surface {
  background-color: #e3e8ee;
}

.Box-background--offset {
  background-color: #f7fafc;
}

.Box-background--gray {
  background-color: #697386;
}

.Box-background--gray900 {
  background-color: #1a1f36;
}

.Box-background--gray800 {
  background-color: #2a2f45;
}

.Box-background--gray700 {
  background-color: #3c4257;
}

.Box-background--gray600 {
  background-color: #4f566b;
}

.Box-background--gray500 {
  background-color: #697386;
}

.Box-background--gray400 {
  background-color: #8792a2;
}

.Box-background--gray300 {
  background-color: #a3acb9;
}

.Box-background--gray200 {
  background-color: #c1c9d2;
}

.Box-background--gray100 {
  background-color: #e3e8ee;
}

.Box-background--gray50 {
  background-color: #f7fafc;
}

.Box-background--blue {
  background-color: #5469d4;
}

.Box-background--blue900 {
  background-color: #131f41;
}

.Box-background--blue800 {
  background-color: #212d63;
}

.Box-background--blue700 {
  background-color: #2f3d89;
}

.Box-background--blue600 {
  background-color: #3d4eac;
}

.Box-background--blue500 {
  background-color: #5469d4;
}

.Box-background--blue400 {
  background-color: #6c8eef;
}

.Box-background--blue300 {
  background-color: #7dabf8;
}

.Box-background--blue200 {
  background-color: #a4cdfe;
}

.Box-background--blue100 {
  background-color: #d6ecff;
}

.Box-background--blue50 {
  background-color: #f5fbff;
}

.Box-background--cyan {
  background-color: #067ab8;
}

.Box-background--cyan900 {
  background-color: #042235;
}

.Box-background--cyan800 {
  background-color: #093353;
}

.Box-background--cyan700 {
  background-color: #06457a;
}

.Box-background--cyan600 {
  background-color: #075996;
}

.Box-background--cyan500 {
  background-color: #067ab8;
}

.Box-background--cyan400 {
  background-color: #3a97d4;
}

.Box-background--cyan300 {
  background-color: #4db7e8;
}

.Box-background--cyan200 {
  background-color: #7fd3ed;
}

.Box-background--cyan100 {
  background-color: #c4f1f9;
}

.Box-background--cyan50 {
  background-color: #edfdfd;
}

.Box-background--green {
  background-color: #09825d;
}

.Box-background--green900 {
  background-color: #082429;
}

.Box-background--green800 {
  background-color: #0b3733;
}

.Box-background--green700 {
  background-color: #0d4b3b;
}

.Box-background--green600 {
  background-color: #0e6245;
}

.Box-background--green500 {
  background-color: #09825d;
}

.Box-background--green400 {
  background-color: #1ea672;
}

.Box-background--green300 {
  background-color: #33c27f;
}

.Box-background--green200 {
  background-color: #85d996;
}

.Box-background--green100 {
  background-color: #cbf4c9;
}

.Box-background--green50 {
  background-color: #efffed;
}

.Box-background--orange {
  background-color: #c44c34;
}

.Box-background--orange900 {
  background-color: #420e11;
}

.Box-background--orange800 {
  background-color: #5d161b;
}

.Box-background--orange700 {
  background-color: #7e1e23;
}

.Box-background--orange600 {
  background-color: #9e2f28;
}

.Box-background--orange500 {
  background-color: #c44c34;
}

.Box-background--orange400 {
  background-color: #e56f4a;
}

.Box-background--orange300 {
  background-color: #f5925e;
}

.Box-background--orange200 {
  background-color: #f8b886;
}

.Box-background--orange100 {
  background-color: #fee3c0;
}

.Box-background--orange50 {
  background-color: #fffaee;
}

.Box-background--purple {
  background-color: #a450b5;
}

.Box-background--purple900 {
  background-color: #2d0f55;
}

.Box-background--purple800 {
  background-color: #401d6a;
}

.Box-background--purple700 {
  background-color: #5b2b80;
}

.Box-background--purple600 {
  background-color: #7b3997;
}

.Box-background--purple500 {
  background-color: #a450b5;
}

.Box-background--purple400 {
  background-color: #c96ed0;
}

.Box-background--purple300 {
  background-color: #e28ddc;
}

.Box-background--purple200 {
  background-color: #f0b4e4;
}

.Box-background--purple100 {
  background-color: #fce0f6;
}

.Box-background--purple50 {
  background-color: #fff8fe;
}

.Box-background--red {
  background-color: #cd3d64;
}

.Box-background--red900 {
  background-color: #420828;
}

.Box-background--red800 {
  background-color: #5e1039;
}

.Box-background--red700 {
  background-color: #80143f;
}

.Box-background--red600 {
  background-color: #a41c4e;
}

.Box-background--red500 {
  background-color: #cd3d64;
}

.Box-background--red400 {
  background-color: #ed5f74;
}

.Box-background--red300 {
  background-color: #fa8389;
}

.Box-background--red200 {
  background-color: #fbb5b2;
}

.Box-background--red100 {
  background-color: #fde2dd;
}

.Box-background--red50 {
  background-color: #fff8f5;
}

.Box-background--yellow {
  background-color: #bb5504;
}

.Box-background--yellow900 {
  background-color: #3a1607;
}

.Box-background--yellow800 {
  background-color: #571f0d;
}

.Box-background--yellow700 {
  background-color: #762b0b;
}

.Box-background--yellow600 {
  background-color: #983705;
}

.Box-background--yellow500 {
  background-color: #bb5504;
}

.Box-background--yellow400 {
  background-color: #d97917;
}

.Box-background--yellow300 {
  background-color: #e5993e;
}

.Box-background--yellow200 {
  background-color: #efc078;
}

.Box-background--yellow100 {
  background-color: #f8e5b9;
}

.Box-background--yellow50 {
  background-color: #fcf9e9;
}

.Box-background--violet {
  background-color: #8260c3;
}

.Box-background--violet900 {
  background-color: #1f184e;
}

.Box-background--violet800 {
  background-color: #352465;
}

.Box-background--violet700 {
  background-color: #4b3480;
}

.Box-background--violet600 {
  background-color: #61469b;
}

.Box-background--violet500 {
  background-color: #8260c3;
}

.Box-background--violet400 {
  background-color: #9c82db;
}

.Box-background--violet300 {
  background-color: #b0a1e1;
}

.Box-background--violet200 {
  background-color: #c7c2ea;
}

.Box-background--violet100 {
  background-color: #e6e6fc;
}

.Box-background--violet50 {
  background-color: #f8f9fe;
}

.Box-divider--light-top-1 {
  box-shadow: inset 0 1px #e3e8ee;
}

.Box-divider--light-bottom-1 {
  box-shadow: inset 0 -1px #e3e8ee;
}

.Box-divider--light-left-1 {
  box-shadow: inset 1px 0 #e3e8ee;
}

.Box-divider--light-right-1 {
  box-shadow: inset -1px 0 #e3e8ee;
}

.Box-divider--light-vertical-1 {
  box-shadow: inset 0 -1px #e3e8ee, inset 0 1px #e3e8ee;
}

.Box-divider--light-horizontal-1 {
  box-shadow: inset -1px 0 #e3e8ee, inset 1px 0 #e3e8ee;
}

.Box-divider--light-all-1 {
  box-shadow: inset 0 0 0 1px #e3e8ee;
}

.Box-divider--medium-top-1 {
  box-shadow: inset 0 1px #c1c9d2;
}

.Box-divider--medium-bottom-1 {
  box-shadow: inset 0 -1px #c1c9d2;
}

.Box-divider--medium-left-1 {
  box-shadow: inset 1px 0 #c1c9d2;
}

.Box-divider--medium-right-1 {
  box-shadow: inset -1px 0 #c1c9d2;
}

.Box-divider--medium-vertical-1 {
  box-shadow: inset 0 -1px #c1c9d2, inset 0 1px #c1c9d2;
}

.Box-divider--medium-horizontal-1 {
  box-shadow: inset -1px 0 #c1c9d2, inset 1px 0 #c1c9d2;
}

.Box-divider--medium-all-1 {
  box-shadow: inset 0 0 0 1px #c1c9d2;
}

.Box-divider--dark-top-1 {
  box-shadow: inset 0 1px #4f566b;
}

.Box-divider--dark-bottom-1 {
  box-shadow: inset 0 -1px #4f566b;
}

.Box-divider--dark-left-1 {
  box-shadow: inset 1px 0 #4f566b;
}

.Box-divider--dark-right-1 {
  box-shadow: inset -1px 0 #4f566b;
}

.Box-divider--dark-vertical-1 {
  box-shadow: inset 0 -1px #4f566b, inset 0 1px #4f566b;
}

.Box-divider--dark-horizontal-1 {
  box-shadow: inset -1px 0 #4f566b, inset 1px 0 #4f566b;
}

.Box-divider--dark-all-1 {
  box-shadow: inset 0 0 0 1px #4f566b;
}

.Box-divider--white-top-1 {
  box-shadow: inset 0 1px #fff;
}

.Box-divider--white-bottom-1 {
  box-shadow: inset 0 -1px #fff;
}

.Box-divider--white-left-1 {
  box-shadow: inset 1px 0 #fff;
}

.Box-divider--white-right-1 {
  box-shadow: inset -1px 0 #fff;
}

.Box-divider--white-vertical-1 {
  box-shadow: inset 0 -1px #fff, inset 0 1px #fff;
}

.Box-divider--white-horizontal-1 {
  box-shadow: inset -1px 0 #fff, inset 1px 0 #fff;
}

.Box-divider--white-all-1 {
  box-shadow: inset 0 0 0 1px #fff;
}

.Box-divider--light-top-2 {
  box-shadow: inset 0 2px #e3e8ee;
}

.Box-divider--light-bottom-2 {
  box-shadow: inset 0 -2px #e3e8ee;
}

.Box-divider--light-left-2 {
  box-shadow: inset 2px 0 #e3e8ee;
}

.Box-divider--light-right-2 {
  box-shadow: inset -2px 0 #e3e8ee;
}

.Box-divider--light-vertical-2 {
  box-shadow: inset 0 -2px #e3e8ee, inset 0 2px #e3e8ee;
}

.Box-divider--light-horizontal-2 {
  box-shadow: inset -2px 0 #e3e8ee, inset 2px 0 #e3e8ee;
}

.Box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}

.Box-divider--medium-top-2 {
  box-shadow: inset 0 2px #c1c9d2;
}

.Box-divider--medium-bottom-2 {
  box-shadow: inset 0 -2px #c1c9d2;
}

.Box-divider--medium-left-2 {
  box-shadow: inset 2px 0 #c1c9d2;
}

.Box-divider--medium-right-2 {
  box-shadow: inset -2px 0 #c1c9d2;
}

.Box-divider--medium-vertical-2 {
  box-shadow: inset 0 -2px #c1c9d2, inset 0 2px #c1c9d2;
}

.Box-divider--medium-horizontal-2 {
  box-shadow: inset -2px 0 #c1c9d2, inset 2px 0 #c1c9d2;
}

.Box-divider--medium-all-2 {
  box-shadow: inset 0 0 0 2px #c1c9d2;
}

.Box-divider--dark-top-2 {
  box-shadow: inset 0 2px #4f566b;
}

.Box-divider--dark-bottom-2 {
  box-shadow: inset 0 -2px #4f566b;
}

.Box-divider--dark-left-2 {
  box-shadow: inset 2px 0 #4f566b;
}

.Box-divider--dark-right-2 {
  box-shadow: inset -2px 0 #4f566b;
}

.Box-divider--dark-vertical-2 {
  box-shadow: inset 0 -2px #4f566b, inset 0 2px #4f566b;
}

.Box-divider--dark-horizontal-2 {
  box-shadow: inset -2px 0 #4f566b, inset 2px 0 #4f566b;
}

.Box-divider--dark-all-2 {
  box-shadow: inset 0 0 0 2px #4f566b;
}

.Box-divider--white-top-2 {
  box-shadow: inset 0 2px #fff;
}

.Box-divider--white-bottom-2 {
  box-shadow: inset 0 -2px #fff;
}

.Box-divider--white-left-2 {
  box-shadow: inset 2px 0 #fff;
}

.Box-divider--white-right-2 {
  box-shadow: inset -2px 0 #fff;
}

.Box-divider--white-vertical-2 {
  box-shadow: inset 0 -2px #fff, inset 0 2px #fff;
}

.Box-divider--white-horizontal-2 {
  box-shadow: inset -2px 0 #fff, inset 2px 0 #fff;
}

.Box-divider--white-all-2 {
  box-shadow: inset 0 0 0 2px #fff;
}

.Margin-top--0 {
  margin-top: 0 !important;
}

.Margin-top--2 {
  margin-top: 2px;
}

.Margin-top--4 {
  margin-top: 4px;
}

.Margin-top--8 {
  margin-top: 8px;
}
.Margin-top---8 {
  margin-top: -8px;
}

.Margin-top--12 {
  margin-top: 12px;
}

.Margin-top--16 {
  margin-top: 16px;
}

.Margin-top--20 {
  margin-top: 20px;
}

.Margin-top--24 {
  margin-top: 24px;
}

.Margin-top--32 {
  margin-top: 32px;
}

.Margin-top--40 {
  margin-top: 40px;
}

.Margin-top--48 {
  margin-top: 48px;
}

.Margin-top--64 {
  margin-top: 64px;
}

.Margin-top--80 {
  margin-top: 80px;
}

.Margin-right--0 {
  margin-right: 0;
}

.Margin-right--2 {
  margin-right: 2px;
}

.Margin-right--4 {
  margin-right: 4px;
}

.Margin-right--8 {
  margin-right: 8px;
}

.Margin-right--12 {
  margin-right: 12px;
}

.Margin-right--16 {
  margin-right: 16px;
}

.Margin-right--20 {
  margin-right: 20px;
}

.Margin-right--24 {
  margin-right: 24px;
}

.Margin-right--32 {
  margin-right: 32px;
}

.Margin-right--48 {
  margin-right: 48px;
}

.Margin-right--64 {
  margin-right: 64px;
}

.Margin-right--80 {
  margin-right: 80px;
}

.Margin-bottom--0 {
  margin-bottom: 0;
}

.Margin-bottom--2 {
  margin-bottom: 2px;
}

.Margin-bottom--4 {
  margin-bottom: 4px;
}

.Margin-bottom--8 {
  margin-bottom: 8px;
}

.Margin-bottom--10 {
  margin-bottom: 10px;
}

.Margin-bottom--12 {
  margin-bottom: 12px;
}

.Margin-bottom--16 {
  margin-bottom: 16px;
}

.Margin-bottom--20 {
  margin-bottom: 20px;
}

.Margin-bottom--24 {
  margin-bottom: 24px;
}

.Margin-bottom--32 {
  margin-bottom: 32px;
}

.Margin-bottom--48 {
  margin-bottom: 48px;
}

.Margin-bottom--64 {
  margin-bottom: 64px;
}

.Margin-bottom--80 {
  margin-bottom: 80px;
}

.Margin-left--0 {
  margin-left: 0;
}

.Margin-left--2 {
  margin-left: 2px;
}

.Margin-left--4 {
  margin-left: 4px;
}

.Margin-left--8 {
  margin-left: 8px;
}

.Margin-left--10 {
  margin-left: 10px;
}

.Margin-left--12 {
  margin-left: 12px;
}

.Margin-left--16 {
  margin-left: 16px;
}

.Margin-left--20 {
  margin-left: 20px;
}

.Margin-left--24 {
  margin-left: 24px;
}

.Margin-left--32 {
  margin-left: 32px;
}

.Margin-left--48 {
  margin-left: 48px;
}

.Margin-left--64 {
  margin-left: 64px;
}

.Margin-left--80 {
  margin-left: 80px;
}

.Margin-vertical--0 {
  margin-top: 0;
  margin-bottom: 0;
}

.Margin-vertical--2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.Margin-vertical--4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.Margin-vertical--8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.Margin-vertical--10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Margin-vertical--12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.Margin-vertical--16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.Margin-vertical--20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Margin-vertical--24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.Margin-vertical--32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.Margin-vertical--48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.Margin-vertical--64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.Margin-vertical--80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.Margin-horizontal--0 {
  margin-right: 0;
  margin-left: 0;
}

.Margin-horizontal--2 {
  margin-right: 2px;
  margin-left: 2px;
}

.Margin-horizontal--4 {
  margin-right: 4px;
  margin-left: 4px;
}

.Margin-horizontal--8 {
  margin-right: 8px;
  margin-left: 8px;
}

.Margin-horizontal--12 {
  margin-right: 12px;
  margin-left: 12px;
}

.Margin-horizontal--16 {
  margin-right: 16px;
  margin-left: 16px;
}

.Margin-horizontal--20 {
  margin-right: 20px;
  margin-left: 20px;
}

.Margin-horizontal--24 {
  margin-right: 24px;
  margin-left: 24px;
}

.Margin-horizontal--32 {
  margin-right: 32px;
  margin-left: 32px;
}

.Margin-horizontal--48 {
  margin-right: 48px;
  margin-left: 48px;
}

.Margin-horizontal--64 {
  margin-right: 64px;
  margin-left: 64px;
}

.Margin-horizontal--80 {
  margin-right: 80px;
  margin-left: 80px;
}

.Margin-all--0 {
  margin: 0;
}

.Margin-all--2 {
  margin: 2px;
}

.Margin-all--4 {
  margin: 4px;
}

.Margin-all--8 {
  margin: 8px;
}

.Margin-all--12 {
  margin: 12px;
}

.Margin-all--16 {
  margin: 16px;
}

.Margin-all--20 {
  margin: 20px;
}

.Margin-all--24 {
  margin: 24px;
}

.Margin-all--32 {
  margin: 32px;
}

.Margin-all--48 {
  margin: 48px;
}

.Margin-all--64 {
  margin: 64px;
}

.Margin-all--80 {
  margin: 80px;
}

.Padding-top--0 {
  padding-top: 0;
}

.Padding-top--2 {
  padding-top: 2px;
}

.Padding-top--4 {
  padding-top: 4px;
}

.Padding-top--8 {
  padding-top: 8px;
}

.Padding-top--12 {
  padding-top: 12px;
}

.Padding-top--14 {
  padding-top: 14px;
}

.Padding-top--16 {
  padding-top: 16px;
}

.Padding-top--20 {
  padding-top: 20px;
}

.Padding-top--24 {
  padding-top: 24px;
}

.Padding-top--32 {
  padding-top: 32px;
}

.Padding-top--48 {
  padding-top: 48px;
}

.Padding-top--64 {
  padding-top: 64px;
}

.Padding-top--80 {
  padding-top: 80px;
}

.Padding-right--0 {
  padding-right: 0;
}

.Padding-right--2 {
  padding-right: 2px;
}

.Padding-right--4 {
  padding-right: 4px;
}

.Padding-right--8 {
  padding-right: 8px;
}

.Padding-right--10 {
  padding-right: 10px;
}

.Padding-right--12 {
  padding-right: 12px;
}

.Padding-right--16 {
  padding-right: 16px;
}

.Padding-right--20 {
  padding-right: 20px;
}

.Padding-right--24 {
  padding-right: 24px;
}

.Padding-right--32 {
  padding-right: 32px;
}

.Padding-right--48 {
  padding-right: 48px;
}

.Padding-right--64 {
  padding-right: 64px;
}

.Padding-right--80 {
  padding-right: 80px;
}

.Padding-bottom--0 {
  padding-bottom: 0;
}

.Padding-bottom--2 {
  padding-bottom: 2px;
}

.Padding-bottom--4 {
  padding-bottom: 4px;
}

.Padding-bottom--8 {
  padding-bottom: 8px;
}

.Padding-bottom--10 {
  padding-bottom: 10px;
}

.Padding-bottom--12 {
  padding-bottom: 12px;
}

.Padding-bottom--16 {
  padding-bottom: 16px;
}

.Padding-bottom--20 {
  padding-bottom: 20px;
}

.Padding-bottom--24 {
  padding-bottom: 24px;
}

.Padding-bottom--32 {
  padding-bottom: 32px;
}

.Padding-bottom--48 {
  padding-bottom: 48px;
}

.Padding-bottom--64 {
  padding-bottom: 64px;
}

.Padding-bottom--80 {
  padding-bottom: 80px;
}

.Padding-left--0 {
  padding-left: 0;
}

.Padding-left--2 {
  padding-left: 2px;
}

.Padding-left--4 {
  padding-left: 4px;
}

.Padding-left--8 {
  padding-left: 8px;
}

.Padding-left--12 {
  padding-left: 12px;
}

.Padding-left--16 {
  padding-left: 16px;
}

.Padding-left--20 {
  padding-left: 20px;
}

.Padding-left--24 {
  padding-left: 24px;
}

.Padding-left--32 {
  padding-left: 32px;
}

.Padding-left--48 {
  padding-left: 48px;
}

.Padding-left--64 {
  padding-left: 64px;
}

.Padding-left--80 {
  padding-left: 80px;
}

.Padding-vertical--0 {
  padding-top: 0;
  padding-bottom: 0;
}

.Padding-vertical--2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.Padding-vertical--4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.Padding-vertical--8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.Padding-vertical--12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.Padding-vertical--16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.Padding-vertical--20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.Padding-vertical--24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.Padding-vertical--32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.Padding-vertical--48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.Padding-vertical--64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.Padding-vertical--80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.Padding-horizontal--0 {
  padding-right: 0;
  padding-left: 0;
}

.Padding-horizontal--2 {
  padding-right: 2px;
  padding-left: 2px;
}

.Padding-horizontal--4 {
  padding-right: 4px;
  padding-left: 4px;
}

.Padding-horizontal--8 {
  padding-right: 8px;
  padding-left: 8px;
}

.Padding-horizontal--12 {
  padding-right: 12px;
  padding-left: 12px;
}

.Padding-horizontal--16 {
  padding-right: 16px;
  padding-left: 16px;
}

.Padding-horizontal--20 {
  padding-right: 20px;
  padding-left: 20px;
}

.Padding-horizontal--24 {
  padding-right: 24px;
  padding-left: 24px;
}

.Padding-horizontal--32 {
  padding-right: 32px;
  padding-left: 32px;
}

.Padding-horizontal--48 {
  padding-right: 48px;
  padding-left: 48px;
}

.Padding-horizontal--64 {
  padding-right: 64px;
  padding-left: 64px;
}

.Padding-horizontal--80 {
  padding-right: 80px;
  padding-left: 80px;
}

.Padding-all--0 {
  padding: 0;
}

.Padding-all--2 {
  padding: 2px;
}

.Padding-all--4 {
  padding: 4px;
}

.Padding-all--6 {
  padding: 6px;
}

.Padding-all--8 {
  padding: 8px;
}

.Padding-all--12 {
  padding: 12px;
}

.Padding-all--16 {
  padding: 16px;
}

.Padding-all--20 {
  padding: 20px;
}

.Padding-all--24 {
  padding: 24px;
}

.Padding-all--32 {
  padding: 32px;
}

.Padding-all--48 {
  padding: 48px;
}

.Padding-all--64 {
  padding: 64px;
}

.Padding-all--80 {
  padding: 80px;
}

.Flex-gap--5 {
  gap: 5px;
}

.Flex-gap--10 {
  gap: 10px;
}

.Flex-gap--15 {
  gap: 15px;
}

.Flex-gap--20 {
  gap: 20px;
}
