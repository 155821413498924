/* Start Accordion Menu */
ul.accordion-menu {
  list-style: none;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
}

ul.accordion-menu li {
  background: none;
  padding: 0;
}

ul.accordion-menu li header {
  background-color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 15px 20px;
  text-align: left;
  transition: all 100ms ease;
  user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.accordion-menu li header {
  background-color: #fafafa;
  border: 1px solid rgb(247, 250, 252);
  border: 1px solid #e9f1f7;
}

ul.accordion-menu li.closed header {
  border-bottom-color: transparent;
}

/* ul.accordion-menu li header:hover {
  background-color: rgb(247, 250, 252);
  border-color: rgb(247, 250, 252);
} */

ul.accordion-menu li section.content {
  border-bottom: 1px solid #e9f1f7;
  border-left: 1px solid #e9f1f7;
  border-right: 1px solid #e9f1f7;
  overflow: hidden;
  padding: 20px;
  transition: all 100ms linear;
}

ul.accordion-menu li.closed section.content {
  max-height: 0;
  padding: 0 20px;
}

ul.accordion-menu li.open header {
  background-color: #fafafa;
  border: 1px solid rgb(247, 250, 252);
  border: 1px solid #e9f1f7;
}

ul.accordion-menu li.open header span.title {
  color: #3c4257;
}
