* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
h1,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
a,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
}
.globalContent {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.common-IntroText {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
}
@media (min-width: 670px) {
  .common-IntroText {
    font-size: 24px;
    line-height: 36px;
  }
}
.common-BodyTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;
}
.common-BodyText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}
.common-Button {
  cursor: pointer;
  margin-top: 15px;
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.common-Button:focus,
.common-Button:hover {
  color: #7795f8;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.common-Button:active {
  color: #555abf;
  background-color: #f6f9fc;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
}
.common-Button--default {
  color: #fff;
  background: #6772e5;
}
.common-Button--default:focus,
.common-Button--default:hover {
  color: #fff;
  background-color: #7795f8;
}
.common-Button--default:active {
  color: #e6ebf1;
  background-color: #555abf;
}
.globalContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (min-width: 880px) {
  .columns {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.columns > section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 880px) {
  .columns > section {
    width: 100%;
  }
}
.container-half {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 15px 20px;
}
@media (min-width: 880px) {
  .container-half {
    max-width: 100%;
    padding-top: 68px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 68px;
  }
}
section.copy {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f6f9fc;
}
section.copy .container-half {
  text-align: center;
  padding-top: 68px;
  padding-bottom: 30px;
}
section.copy .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(62, 164, 235, 0.18);
}
@media (min-width: 880px) {
  section.copy .icon {
    width: 78px;
    height: 78px;
  }
}
section.copy span.h1,
section.copy span.p {
  position: relative;
  width: 100%;
  max-width: 400px;
}
@media (min-width: 880px) {
  section.copy span.h1,
  section.copy span.p {
    max-width: none;
  }
}
section.copy span.h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (min-width: 880px) {
  section.copy span.h1 {
    margin-top: 30px;
  }
}
section.copy h1 {
  font-weight: 400;
  line-height: 50px;
}
section.copy .done {
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
  pointer-events: none;
}
section.form {
  background-color: white;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media (min-width: 880px) {
  section.form {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
  }
}
section.form h3 {
  margin-bottom: 20px;
}
section.form h4 {
  line-height: 18px;
  max-width: 380px;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 880px) {
  section.form h4 {
    margin-top: 60px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}
section.form .rating-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 375px;
  padding-bottom: 9px;
}
@media (min-width: 880px) {
  section.form .rating-control {
    max-width: 400px;
  }
}
section.form .rating-control:before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  top: 50%;
  margin-top: -13px;
  left: 10%;
  background-color: #e9ecee;
}
section.form .rating-control .current-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 20%;
  height: 55px;
  top: 0;
  left: 0;
  will-change: transform;
  cursor: -webkit-grab;
  cursor: grab;
  transition: 0.4s ease-in-out 0.1s;
}
section.form .rating-control .current-rating:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
section.form .rating-control .current-rating .svg-wrapper {
  position: relative;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  pointer-events: none;
}
section.form .rating-control .current-rating .svg-wrapper svg {
  position: absolute;
  width: 55px;
  height: 55px;
  top: 0;
  left: 0;
  will-change: transform;
}
@media (-webkit-min-device-pixel-ratio: 1.3),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
  section.form .rating-control .current-rating .svg-wrapper svg {
    -webkit-transform: translate(0.5px, 0.5px);
    transform: translate(0.5px, 0.5px);
  }
}
section.form .rating-control .rating-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 9px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
section.form .rating-control .rating-option:active .icon svg .base {
  fill: #8b959a;
}
section.form .rating-control .rating-option:active .label {
  color: #313b3f !important;
}
section.form .rating-control .rating-option .icon {
  width: 36px;
  height: 36px;
  pointer-events: none;
  transition: 0.4s ease-out 0.2s;
}
section.form .rating-control .rating-option .icon svg {
  display: block;
}
section.form .rating-control .rating-option .icon svg .base {
  -webkit-transition: fill 0.1s ease-in-out;
  transition: fill 0.1s ease-in-out;
}
section.form .rating-control .rating-option .label {
  font-size: 12px;
  font-weight: 500;
  color: #abb2b6;
  margin-top: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  will-change: transform;
  -webkit-transition: color 0.1s ease-in-out;
  transition: all 0.1s ease-out 0.4s;
}
section.form .form-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (min-width: 880px) {
  section.form .form-controls {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
section.form .form-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}
section.form textarea {
  display: block;
  width: 100%;
  min-height: 83px;
  padding: 7px 20px 7px 13px;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  color: #32325d;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-family: Camphor, Open Sans, Segoe UI, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  -webkit-transition: border 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
section.form textarea:active,
section.form textarea:focus {
  border: 1px solid #3ea4eb;
  -webkit-box-shadow: 0 0 0 2px rgba(62, 164, 235, 0.15);
  box-shadow: 0 0 0 2px rgba(62, 164, 235, 0.15);
}
section.form textarea::-webkit-input-placeholder {
  color: #8898aa;
  -webkit-transition: color 0.08s ease-in;
  transition: color 0.08s ease-in;
}
section.form textarea:-ms-input-placeholder,
section.form textarea::-ms-input-placeholder {
  color: #8898aa;
  -webkit-transition: color 0.08s ease-in;
  transition: color 0.08s ease-in;
}
section.form textarea::placeholder {
  color: #8898aa;
  -webkit-transition: color 0.08s ease-in;
  transition: color 0.08s ease-in;
}
section.form a.submit {
  width: 100%;
  margin-top: 12px;
}
@media (min-width: 880px) {
  section.form a.submit {
    width: auto;
    margin-top: 15px;
  }
}
section.form a.submit .spinner {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: opacity 0.25s ease-out,
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}
section.form a.submit .spinner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
}
section.form a.submit .checkmark {
  position: absolute;
  width: 15px;
  height: 13px;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -7px;
  background-image: url(https://spiceblue.co/img/csat/checkmark-small.svg);
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
section.form a.submit .checkmark,
section.form a.submit .label {
  -webkit-transition: opacity 0.25s ease-out,
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.25s ease-out,
    transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}
section.form a.submit .label {
  display: inline-block;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

section.form
  .rating-control
  .current-rating
  .svg-wrapper
  path:not([class*="svg__base"]) {
  fill: #655f52;
}

.common-Button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Legacy Feedback Review */
.FeedbackReviewLegacy__container {
  padding: 60px 100px;
  background: #ffffff;
  border: 1px solid #e3e8ee;
  border-radius: 4px;
}

.FeedbackReviewLegacy__heading {
  font-size: 22px;
  padding-bottom: 20px;
  font-weight: 500;
}

.FeedbackReviewLegacy__icon {
  margin-bottom: 20px;
}

.FeedbackReviewLegacy__content {
  color: #5f6c89;
  margin-bottom: 50px;
}

.FeedbackReviewLegacy__reviews {
  align-self: normal;
}

.FeedbackReviewLegacy__reviews--item {
  padding: 20px 30px;
  border-top: 1px solid #e6ebf1;
}

.FeedbackReviewLegacy__reviews--item:last-child {
  border-bottom: 1px solid #e6ebf1;
}

.FeedbackReviewLegacy__reviews--star {
  padding: 0 0.07em;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  font-size: 25px;
  color: #ddd;
}

.FeedbackReviewLegacy__reviews--star-fill {
  color: #ffd700;
}
